@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.form-check-inline {
    margin-bottom: 0.25rem;
}

.lobby-header {
    text-align: center;
    font-size: 20px;
    text-shadow: #FFF 1px 1px 15px;
    padding: 4px 0px;
    // margin-top: 10px;
    margin-bottom: 10px;

}

.lobby-card {
    position: relative;
    border-radius: 0.25rem;
    box-shadow: 1px 1px 1px #666;
    background-color: rgba(0, 0, 0, 0.65);
    border: 1px solid #666;
    margin-bottom: 10px;
    padding: 15px;

    >div {
        margin-bottom: 10px;
    }

    hr {
        border-top-color: white;
        width: 100px;
    }
}

.no-games-notice {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}