@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.squishable-card-panel {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    // min-height: 0;
    // padding: 4px;
    position: relative;

    .card-wrapper {
        // margin: 0 5px 0 0;
    }
}

.squishable-card-panel.squish .card-wrapper {
    position: absolute;
}

.squishable-card-panel.rotated {
    border-left: 18px solid $darkblue;
    margin-right: 15px;
    min-height: 100px;
    padding-left: 5px;
}