@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.navbar {
    font-size: 1rem;
}

.navbar-sm {
    min-height: $navbar-height;
    height: $navbar-height;
}

// .navbar-sm .navbar-nav>li>span {
//     padding: 0 15px;
//     line-height: 36px;
// }

.patreon-link {
    color: #CCC;
}