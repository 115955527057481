@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.menu {
    position: absolute;
    width: 200px;
    left: 58px;
    top: -5px;
    font-size: 12px;
    line-height: 16px;
    z-index: $layer-card-menu;
    background-color: #000;
}

.bottom-menu {
    position: absolute;
    width: 200px;
    left: 58px;
    bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    z-index: $layer-card-menu;
    background-color: #000;
}

.menu-item {
    border: 1px solid $primary;
    padding: 3px 6px;
    border-radius: 4px;
    margin-top: 4px;
    cursor: pointer;

    &:hover {
        border-color: $info;
    }

    &.disabled {
        background-color: fadeout(#222, 20%);
        color: #666;
        cursor: not-allowed;

        &:hover {
            border-color: $primary;
        }
    }
}

.menu-title {
    // border: 1px solid $primary;
    background-color: $primary;
    padding: 3px 6px;
    // border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    // font-weight: bold;
    font-size: 1.2em;
}

.close-menu-button {
    position: absolute;
    right: 4px;
}