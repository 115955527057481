@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.movable-panel {
    z-index: $layer-card-menu;
}

.panel-heading {
    text-align: center;
    font-weight: bold;
    color: white;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.close-button {
    color: $body-color;
    margin-bottom: 0 !important;
    vertical-align: middle !important;
}

.panel-primary {
    border-color: $primary;

    .panel-heading {
        background-image: url('~assets/img/panel-primary.png');
        border-color: $primary;
    }
}

.panel-body {
    padding: 15px;
    overflow-y: auto;
    height: 100%;
}

.zoom-buttons {
    position: absolute;
    left: 10px;

    a {
        margin: 0px 5px !important;
    }

    .zoom-plus {
        color: $body-color;
        // margin-bottom: 0 !important;
        vertical-align: middle !important;
        margin-right: 0px;
    }

    .zoom-minus {
        color: $body-color;
        // margin-bottom: 0 !important;
        vertical-align: middle !important;
    }
}