@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.splash {
    position: absolute !important;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: $layer-top;
    // min-width: 500px;
    background-color: rgb(0, 0, 0, 0.9) !important;

    // img {
    //     width: 210px;
    //     height: 294px;
    // }

    .splash-card-wrapper {
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: center;
    }

    .splash-card {
        width: 90px;
        height: 90px;
        overflow: hidden;
        border-radius: 4px;
    }

    .img-card {
        width: 90px;
    }

    .card-body {
        // display: flex;
    }

    .splash-loser {
        width: 105px;
        height: 147px;
    }

    p {
        text-align: center;
        margin-bottom: 0px;
    }

    .central {
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        min-width: 250px;

        gap: 10px;

        h2 {
            color: #ddd;
            text-align: center;
        }
    }

    .buttonDiv {
        width: 130px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }

    .splash-stats-box-columns {
        display: flex;
        margin-top: 10px;
        margin-bottom: 20px;
        justify-content: center;

        .stat-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 70px;

            .stat {
                font-size: 20px;
                height: 24px;
                margin-top: 5px;
            }
        }

        .decklist-entry-image {
            height: 40px;
        }

        .cards-icon {
            width: 24px;
            height: 24px;
            vertical-align: text-bottom;
        }

        .stat-icon {
            width: 24px;
            height: 24px;
        }

        .stat-vs {
            width: 24px;
            height: 40px;
            line-height: 40px;
        }

    }
}