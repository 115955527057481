.replay-header {
    // background-color: red;
}

.replay-step-title {
    text-align: center;
    margin-bottom: 8px;
}

.replay-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
    align-items: center;
}

.replay-control {
    width: 32px !important;
    height: 32px;
    border-color: white;
    cursor: pointer;
}