@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.separator {
    hr {
        border-color: $primary;
    }
}

.username {
    font-weight: bold;
    color: white;
}

.message {
    word-wrap: break-word;
    line-height: 1.2;
    max-width: 290px;
    // font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 0 0.4rem 0.4rem 0.4rem !important;
}

.icon {
    color: gold;
}

.icon-e {
    color: #C64B65
}

.icon-u {
    color: #55ccf7
}

.message.this-player {
    background: #0035734d;

    &:hover {
        background: #0d3c7180;

    }
}

.message.other-player {
    background: #791e1e4d;
}

.message.other-player:hover {
    background: #791e1e85;
}

.message-chat {
    b {
        color: white;
    }
}

.chat-bubble .message-chat {
    background: #ffffff3d;
    border-radius: 5px 5px 0 0;
    padding: 5px;

    .gravatar {
        width: 14px;
        height: 14px;
        margin-top: -3px;
    }
}

.chat-bubble .message-fragment+.message-fragment {
    background: #ffffff3d;
    border-radius: 0 0 5px 5px;
    padding: 0 5px 5px 5px;
    position: relative;
    margin-bottom: 8px;
    display: block;
}

.chat-bubble .message-fragment+.message-fragment:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #ffffff3d;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -10px;
}

.messages {
    height: 100%;
    overflow: auto;
    margin: 0;
    font-size: 12px;
    overflow-y: auto;
    flex: 1;
    display: flex;

    .alert {
        margin-bottom: 0;
        padding: 5px;
    }
}

.messages .mb-1 {
    margin-bottom: 0 !important;
    padding-bottom: 0.25rem;
}

.messages .mt-2 {
    margin-top: 0 !important;
    margin-bottom: 0.2rem;
}

.admin-role {
    color: #e74c3b;
}

.contributor-role {
    color: #2bbbbb;
}

.supporter-role {
    color: #2ecc71;
}

.winner-role {
    color: #eee01d;
}

.previouswinner-role {
    color: #fa9d07;
}

.endofround {
    font-size: 0.9rem;
}

.phasestart {
    font-size: 1rem;
}

.startofround {
    font-size: 1.05rem;
    background-image: url('~assets/img/panel-primary.png');
    border-top: 1px solid $primary;
    text-transform: uppercase;
    // font-family: Keyforge, Helvetica, sans-serif;
    font-weight: 300 !important;
    padding: 7px 10px 10px 6px;
    margin: 0px -6px -7px -6px;
}

.startofround .username {
    text-transform: none;
    font-weight: 300 !important;
}

.icon-amber {
    height: 12px;
    width: 12px;
}

.chat-house-icon {
    height: 12px;
    width: 12px;
}

.icon-card {
    height: 16px;
    width: 12px;
    margin-top: -4px;
}

.card-link {
    cursor: pointer;
    cursor: hand;
    color: $secondary;

    &.unique {
        color: goldenrod;
    }

    &.highlight {
        color: #DDD;
    }
}

.card-link:hover {
    color: $info;
}

.card-ff {
    // position: absolute;
    // right: 2px;
    // width: 10px;
    // height: 10px;
}

.alert {
    .user-role {
        color: $info;
    }

    .username {
        font-weight: bold;
        color: inherit;
    }
}

@mixin action-colors($text, $background) {
    color: $text !important;
    background: $background;
    border-color: $text;

}

.chat-action {
    // @include action-colors(#333, #FFF);
    // border-radius: 3px;
    // padding: 2px;
    margin-right: 3px;
    // // width: 17px;
    // text-align: center;
    // display: inline-block;
    // border: 1pt solid black;

    svg {
        color: #FFF !important;
    }
}

// .chat-die {
//     border-radius: 3px;
//     text-align: center;
//     display: inline-block;
//     padding: 2px;
// }