@import '../../styles/variables';

.archon-zoom {
    position: fixed;
    top: 0px;
    left: 0px;
    width: $keyteki-card-width * 4;
    z-index: 999;

    >.img-fluid {
        border-radius: 6px;
    }
}