@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.modal-title {
    font-weight: bold;
    color: white;
    font-size: 0.9rem;
}

.modal-header {
    text-align: center;
}

.modal-content {
    background-color: rgba($dark, 0.9);
    // border: 2px solid #999;
    box-shadow: 0px 0px 8px #999999;
}

.modal-dialog {
    max-width: 780px;

}

.deck-list-header {
    margin-left: 15px;
}

.close {
    color: white;
}

.modal-body {
    .react-tabs__tab {
        background-color: #333;
        border-radius: 5px 5px 0 0;
    }

    .react-tabs__tab:hover {
        background-color: #666;
    }

    .react-tabs__tab--selected {
        background-color: white;
    }
}

.banner-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}