@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.card-zoom {
    right: 5px;
    top: 5px;
    position: absolute;
    z-index: $layer-top;
    pointer-events: none;

    &.small {
        width: $keyteki-card-width * 3;
        height: $keyteki-card-height * 3;

        &.status {
            height: $keyteki-card-height *1.5;

        }
    }

    &.normal {
        width: $keyteki-card-width * 4;
        height: $keyteki-card-height * 4;

        &.status {
            height: $keyteki-card-height *2;

        }
    }

    &.large {
        width: $keyteki-card-width * 5;
        height: $keyteki-card-height * 5;

        &.status {
            height: $keyteki-card-height *2.5;

        }
    }

    &.x-large {
        width: $keyteki-card-width * 6;
        height: $keyteki-card-height * 6;

        &.status {
            height: $keyteki-card-height *3;

        }
    }

    &.left {
        right: auto;
        left: 5px;
        width: 210px;
    }
}

.card-zoomed {
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 12px;
        z-index: $layer-top;
        width: 100%;
        height: 100%;
    }
}

.zoom-condition {
    margin-top: 4px;

    img {
        border-radius: 12px;
        z-index: $layer-top;
    }
}