@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.deck-selection {
    padding: 6px 12px;
    border: 1px solid black;
    border-radius: 4px;
    background: $primary;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
}

span.deck-selection {
    padding: 10px;
}

.player-row {
    min-height: 35px;
}