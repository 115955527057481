@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/**
  * Generates the specified property based on the calculation of:
  * property: numCards * (cardSize + cardSpacing) + additionalOffset
  *
  * for the various different card sizes. Example, mixing in
  * @include calculate-tiled-card-prop(min-height, 2, height, 3px) will generate CSS for:
  * min-height: 2 * (@card-height + 5px) + 3px
  * &.small {
  *   min-height: 2 * (@card-sm-height + 5px) + 3px
  * }
  * ... etc ...
  */

@function card-var($value) {
    @if $value =='keyteki-card-height' {
        @return $keyteki-card-height;
    }

    @else if $value =='keyteki-card-width' {
        @return $keyteki-card-width;
    }

    @else if $value =='keyteki-card-lg-height' {
        @return $keyteki-card-lg-height;
    }

    @else if $value =='keyteki-card-lg-width' {
        @return $keyteki-card-lg-width;
    }

    @else if $value =='keyteki-card-sm-height' {
        @return $keyteki-card-sm-height;
    }

    @else if $value =='keyteki-card-sm-width' {
        @return $keyteki-card-sm-width;
    }

    @else if $value =='keyteki-card-xl-height' {
        @return $keyteki-card-xl-height;
    }

    @else if $value =='keyteki-card-xl-width' {
        @return $keyteki-card-xl-width;
    }
}

@mixin calculate-tiled-card-prop($property, $numCards, $cardMeasurement, $additionalOffset: 0px) {
    #{$property}: $numCards * (card-var('keyteki-card-#{$cardMeasurement}') + 10px)+$additionalOffset;

    &.small {
        #{$property}: $numCards * (card-var('keyteki-card-sm-#{$cardMeasurement}') + 10px)+$additionalOffset;
    }

    &.normal {
        #{$property}: $numCards * (card-var('keyteki-card-#{$cardMeasurement}') + 10px)+$additionalOffset;
    }

    &.large {
        #{$property}: $numCards * (card-var('keyteki-card-lg-#{$cardMeasurement}') + 10px)+$additionalOffset;
    }

    &.x-large {
        #{$property}: $numCards * (card-var('keyteki-card-xl-#{$cardMeasurement}') + 10px)+$additionalOffset;
    }
}

.text {
    // display: none;
    min-width: max-content;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 0.9rem;
}

.count {
    min-width: max-content;
    margin-left: 2px;
    margin-right: 5px;
    font-size: 120%;
    // font-weight: bold;
}


.inner {
    // @include calculate-tiled-card-prop(max-height, 4, height);
    // @include calculate-tiled-card-prop(height, 3, height, -5);
    overflow-y: auto;

    &.hand-popup {
        @include calculate-tiled-card-prop(height, 1, height);
    }
}

.card-pile-link {
    display: flex;
    align-items: center;
    padding: 0px;
    position: relative;
    margin: 0px 5px;
    background-color: #888;
    border-radius: 4px;
    border: #333 solid 1pt;
    cursor: pointer;

    .icon {
        // width: 21.4px;
        height: 26px;
        margin: 1px 3px;
    }

    .panel {
        z-index: $layer-card-menu;
        background-color: rgba(0, 0, 0, 0.85);
        position: fixed;
        max-width: 800px;
        min-width: 200px;

        a {
            display: inline-block;
            margin: 0 10px 8px 0;
            text-decoration: none;

            &:hover {
                color: $info;
                cursor: pointer;
            }
        }

        .panel {
            margin-bottom: 0;
            padding: 5px;
        }

        .panel-title {
            margin: 5px 5px;
        }

        .drop-target {
            height: calc(100% - 50px);
        }
    }
}

.card-pile-link.spent {
    background-color: #444 !important;
}

.card-pile-link.draw {
    background-color: #666 !important;
}

.discard,
.spent,
.purged,
.hand-popup {
    .panel {
        min-height: 146px;
        z-index: $layer-card-menu !important;

        //     .inner {
        //         @include calculate-tiled-card-prop(width, 5, width, $scrollbar-width);
        //         padding: 4px;
        //     }
    }
}

// .draw {
//     .panel {
//         z-index: $layer-card-menu;
//         @include calculate-tiled-card-prop(width, 7, width, $scrollbar-width + 10);
//         @include calculate-tiled-card-prop(height, 3, height);

//         .inner {
//             @include calculate-tiled-card-prop(width, 7, width, $scrollbar-width);
//         }
//     }
// }

// .hand {
//     .panel {
//         z-index: $layer-card-menu;
// @include calculate-tiled-card-prop(width, 6, width, $scrollbar-width + 10);
// @include calculate-tiled-card-prop(height, 1, height);

// .inner {
//     @include calculate-tiled-card-prop(width, 6, width, $scrollbar-width);
// }
//     }
// }

// .additional-cards {
//     .panel {
//         .inner {
//             @include calculate-tiled-card-prop(min-width, 2, height, $scrollbar-width);
//         }
//     }
// }