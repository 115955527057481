@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.profile-avatar {
    width: 24px;
    height: 24px;
}

.profile-submit {
    position: sticky;
    bottom: 10px;
    background-color: $primary;
    z-index: $layer-top;
}

.profile-form {
    .card {
        margin-top: 10px;
    }
}