@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.game-row {
    // min-height: 130px;
    padding: 10px 5px;
    border-bottom: 1px solid grey;
}

.game-player-row {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.first-player {
        margin-right: 10px;
        align-items: flex-end;
    }

    &.other-player {
        margin-left: 10px;
        align-items: flex-start;
    }
}

.game-faction-row {
    display: flex;
    align-items: center;
    flex: 1;

    .gamelist-button {
        height: 34px;
        width: 122px;
    }
}

.game-header-row {
    text-align: center;
    font-size: 1em;

    .game-format {
        padding: 8px;
    }
}

.game-middle-row {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-row-buttons {
    text-align: center;
}

.game-player-name {
    font-size: 1.1em;
}

.game-header {
    padding: 5px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    border-radius: 4px;

}

.game-casual {
    background-color: rgb(255, 193, 7, 0.5);
}

.game-comp {
    background-color: rgb(118, 22, 22, 0.8);
}

.cap {
    text-transform: capitalize;
}

.first-player {
    .game-player-name {
        margin-right: 5px;
    }
}

.other-player {
    .game-player-name {
        margin-left: 5px;
    }
}

.join-buttons {
    display: flex;
    flex-direction: column;
}

.game-type {
    margin-top: 5px;
}

.house-icons {
    display: flex;
    margin-top: 4px;

    img {
        height: 21px;
    }
}

.game-list {
    hr {
        border-top-color: $primary;
        margin: 0;
    }
}

.game-row:hover {
    border-color: $info;
    background-color: rgba($info, 0.2);
}

.game-row.active {
    border-color: $info;
    color: $danger;
}

.node1 {
    background-color: rgba($warning, 0.2);
}

.node2 {
    background-color: rgba($danger, 0.2);
}

.game-list-icon {
    height: 1.5em;
    width: 1.5em;
    filter: invert(90%);
}

.game-list-icon-white {
    height: 1.5em;
    width: 1.5em;
}

.game-list-deck-image {
    margin-left: 5px;
    margin-right: 5px;
    width: 50px;
}

.pb-image {
    border-radius: 2px;
}

.game-icons {
    margin-left: 5px;

    img {
        margin-right: 3px;
    }

    svg {
        margin-right: 3px;
    }
}