@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $layer-top;
    opacity: 0.5;

    pointer-events: none;

    &.drop-ok {
        background-color: $success;
    }

    &.can-drop {
        background-color: $warning;
    }

    &.no-drop {
        background-color: $danger;
    }
}