.champ-selector,
.chosen-champions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-button.mini {
    width: 130px;
    min-width: 130px;
    height: 150px;
}

.mini .img-button-label {
    font-size: 12px;
}

.image-button-filler {
    width: 130px;
    font-weight: bold;
    align-content: center;
    text-align: center;
}