@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.status-image {
    width: 100px;
    border-radius: 5px;
    transform: rotate(90deg);
    margin-top: 13px;
    width: 90px; // $keyteki-card-width * 2;
    box-shadow: 0 -1px 3px #111;

    &.selected {
        box-shadow: 0 0 1px 4px green;
    }
}