@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.target-card {
    border-radius: 6.25%;
    flex-shrink: 1;
    //margin: 0 2px;
    max-height: $keyteki-card-height;
    max-width: $keyteki-card-width;
    overflow: hidden;
    position: relative;
}

.prompt-control-targeting {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    svg {
        margin: 3px;
    }
}