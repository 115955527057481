@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/**
  * Generates the specified property based on the calculation of:
  * property: numCards * (cardSize + cardSpacing) + additionalOffset
  *
  * for the various different card sizes. Example, mixing in
  * @include calculate-tiled-card-prop(min-height, 2, height, 3px) will generate CSS for:
  * min-height: 2 * (@card-height + 5px) + 3px
  * &.small {
  *   min-height: 2 * (@card-sm-height + 5px) + 3px
  * }
  * ... etc ...
  */

@function card-var($value) {
    @if $value =='keyteki-card-height' {
        @return $keyteki-card-height;
    }

    @else if $value =='keyteki-card-width' {
        @return $keyteki-card-width;
    }

    @else if $value =='keyteki-card-lg-height' {
        @return $keyteki-card-lg-height;
    }

    @else if $value =='keyteki-card-lg-width' {
        @return $keyteki-card-lg-width;
    }

    @else if $value =='keyteki-card-sm-height' {
        @return $keyteki-card-sm-height;
    }

    @else if $value =='keyteki-card-sm-width' {
        @return $keyteki-card-sm-width;
    }

    @else if $value =='keyteki-card-xl-height' {
        @return $keyteki-card-xl-height;
    }

    @else if $value =='keyteki-card-xl-width' {
        @return $keyteki-card-xl-width;
    }
}

@mixin calculate-tiled-card-prop($property, $numCards, $cardMeasurement, $additionalOffset: 0px) {
    #{$property}: $numCards * (card-var('keyteki-card-#{$cardMeasurement}') + 5px)+$additionalOffset;

    &.small {
        #{$property}: $numCards * (card-var('keyteki-card-sm-#{$cardMeasurement}') + 5px)+$additionalOffset;
    }

    &.large {
        #{$property}: $numCards * (card-var('keyteki-card-lg-#{$cardMeasurement}') + 5px)+$additionalOffset;
    }

    &.x-large {
        #{$property}: $numCards * (card-var('keyteki-card-xl-#{$cardMeasurement}') + 5px)+$additionalOffset;
    }
}

.inner {
    // @include calculate-tiled-card-prop(max-height, 4, height);
    overflow-y: auto;
}

.card-pile {
    padding: 0px;
    position: relative;
    margin: 0px 5px;

    &.small {
        &.vertical {
            height: $keyteki-card-sm-height;
            min-width: $keyteki-card-sm-width;
        }

        &.horizontal {
            height: $keyteki-card-sm-width;
            width: $keyteki-card-sm-height;
        }
    }

    &.large {
        &.vertical {
            height: $keyteki-card-lg-height;
            min-width: $keyteki-card-lg-width;
        }

        &.horizontal {
            height: $keyteki-card-lg-width;
            width: $keyteki-card-lg-height;
        }
    }

    &.x-large {
        &.vertical {
            height: $keyteki-card-xl-height;
            min-width: $keyteki-card-xl-width;
        }

        &.horizontal {
            height: $keyteki-card-xl-width;
            width: $keyteki-card-xl-height;
        }
    }

    &.vertical {
        height: $keyteki-card-height;
        min-width: $keyteki-card-width;
    }

    &.horizontal {
        height: $keyteki-card-width;
        width: $keyteki-card-height;
    }

    .card-placeholder {
        height: $keyteki-card-height;
    }

    .panel {
        z-index: $layer-card-menu;
        background-color: rgba(0, 0, 0, 0.85);
        position: fixed;
        max-width: 800px;
        min-width: 200px;

        a {
            display: inline-block;
            margin: 0 10px 8px 0;
            text-decoration: none;

            &:hover {
                color: $info;
                cursor: pointer;
            }
        }

        .panel {
            margin-bottom: 0;
            padding: 5px;
        }

        .panel-title {
            margin: 5px 5px;
        }

        .drop-target {
            height: calc(100% - 50px);
        }
    }
}

.draw {
    .panel {
        .inner {
            // @include calculate-tiled-card-prop(min-height, 1, height);
            // @include calculate-tiled-card-prop(width, 8, width, $scrollbar-width);
        }
    }
}

.additional-cards {
    .panel {
        // .inner {
        //     @include calculate-tiled-card-prop(min-height, 2, width);
        //     @include calculate-tiled-card-prop(min-width, 2, height, $scrollbar-width);
        // }
    }
}

.discard {
    .panel {
        min-height: 146px;

        // .inner {
        //     @include calculate-tiled-card-prop(min-height, 1, height);
        //     @include calculate-tiled-card-prop(width, 5, width, $scrollbar-width);
        //     padding: 4px;
        // }
    }
}

.spent {
    .panel {
        min-height: 146px;

        // .inner {
        //     @include calculate-tiled-card-prop(min-height, 1, height);
        //     @include calculate-tiled-card-prop(width, 5, width, $scrollbar-width);
        //     padding: 4px;
        // }
    }
}

.purged {
    .panel {
        // .inner {
        //     @include calculate-tiled-card-prop(min-height, 1, height);
        //     @include calculate-tiled-card-prop(width, 5, width, $scrollbar-width);
        // }
    }
}

// .small {
//     .inner {
//         height: $keyteki-card-height * 3;
//     }
// }

// .normal {
//     .inner {
//         height: $keyteki-card-height * 4;
//     }
// }

// .large {
//     .inner {
//         height: $keyteki-card-height * 5;
//     }
// }

// .x-large {
//     .inner {
//         height: $keyteki-card-height * 6;
//     }
// }