@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.deck-card-link {
    cursor: pointer;
    cursor: hand;
    color: $secondary;
}

.deck-card-link:hover {
    color: $info;
}

.small-card-icon {
    width: 16px;
    height: 16px;
}

.deck-summary {
    ul {
        padding: 0;
    }

    li {
        list-style: none;
        margin: 4px 0;
    }

    .deck-cards {
        margin-top: 4px;
    }

    .deck-dice {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }
}

.decklist-card-zoom {
    position: fixed;
    top: 0px;
    left: 0px;
    width: $keyteki-card-width * 4;
    z-index: 999;

    img {
        border-radius: 6px;
    }
}

.enhanced-card {
    color: #4f779b;
}

.deck-card-group {
    position: relative;
    margin: 5px 5px 5px 0;
    padding: 10px;
    border: 1px solid rgba(71, 58, 56, 0.65);

    >.game-card {
        margin: 5px;

    }
}

.deck-notes {
    width: 100%;
    white-space: pre-wrap;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.card-group-title {
    background-color: $primary;
    color: white;
    margin: 5px 5px 0 0;
    padding: 5px 1px;
    border: 1px solid $primary;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    text-align: center;
    position: relative;
    top: 5px;
}

.basic-title {
    width: 100%;
    background-color: $primary;
    color: white;
    margin: 5px 5px 0 0;
    padding: 5px 1px;
    border: 1px solid $primary;
    border-radius: 4px 4px 0 0;
    text-align: center;
}

.cards-no-break {
    width: 50%
}

.cards-no-break-100 {
    width: 100%;
}

.cards {
    flex-flow: row wrap;
    display: flex;
    width: 100%;

    .game-card {
        margin: 4px;

        >.card-index {
            background-color: darkgreen;
        }
    }
}

.deck-cards-header {
    position: relative;

    .total-box {
        position: absolute;
        right: 4px;
        top: 4px;
        font-size: 20px;
        line-height: 34px;
    }
}