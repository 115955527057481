@font-face {
  font-family: "SaveYourBaconWebfont";
  src: url('~assets/fonts/saveyourbacon-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SaveYourBaconWebfont";
  src: url('~assets/fonts/saveyourbacon-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SaveYourBaconWebfont";
  src: url('~assets/fonts/saveyourbacon-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SaveYourBaconWebfont";
  src: url('~assets/fonts/saveyourbacon-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// .icon {
//   font-family: "SaveYourBaconWebfont";
//   // font-size: 1.1em;
//   // color: gold;
// }

// .icon-e {
//   font-family: "SaveYourBaconWebfont";
//   // font-size: 1.1em;
//   color: rgb(218, 54, 95);
// }