@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.counters {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: $layer-cards + 1;
}

.game-card.vertical .counters {
    flex-direction: column;

    &.many-counters {
        flex-direction: row;
    }
}

.game-card.horizontal .counters {
    flex-direction: row;

    &.many-counters {
        flex-direction: column;
    }
}

.counter {
    padding: 0;
    font-size: 10px;
    width: 25px;
    height: 25px;
    line-height: 26px;
    text-align: center;
    margin: 2px;
    text-shadow: -0.5px -0.5px 2px #000, 0.5px -0.5px 2px #000, -0.5px 0.5px 2px #000,
        0.5px 0.5px 2px #000;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.fade-out {
        opacity: 0.7;
    }

    &.cancel {
        position: relative;

        &:after {
            border-bottom: 0.17em solid $danger;
            content: '';
            left: 0;
            margin-top: calc(0.17em / 2 * -1);
            position: absolute;
            right: 0;
            top: 50%;
        }
    }


    &.damage {
        background-image: url('~assets/img/damage.png');
    }

    &.armor {
        background-image: url('~assets/img/armor.png');
    }

    &.guarded {
        background-image: url('~assets/img/guarded.png');
    }

    &.preventAllDamage {
        background-image: url('~assets/img/preventAllDamage.png');
    }

    &.terrifying {
        background-image: url('~assets/img/terrifying.png');
    }

    &.overkill {
        background-image: url('~assets/img/overkill.png');
    }

    // &.bypass {
    //     background-image: url('~assets/img/hypnotize.png');
    // }

    &.quickStrike {
        background-image: url('~assets/img/lightning.png');
    }

    &.grouptactics {
        background-image: url('~assets/img/grouptactics.png');
    }

    &.cannotBeAttackTarget {
        background-image: url('~assets/img/cannotBeAttackTarget.png');
    }

    &.cannotattack {
        background-image: url('~assets/img/cannotAttack.png');
    }

    &.cannotblock {
        background-image: url('~assets/img/cannotBlock.png');

    }

    &.cannotguard {
        background-image: url('~assets/img/guarded.png');

    }

    &.preventBlock {
        background-image: url('~assets/img/cannotBeBlocked.png');

    }
}