.server-status {
    display: flex !important;
    line-height: 40px;

    span {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

.disconnect-splash {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 28px;
    border: 1pt dashed red;
}