@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.game-board {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; //$navbar-height;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: clip;

    &.select-cursor {
        cursor: url('~assets/img/crosshairs.cur'), default;
    }

    .card {
        position: relative;
        margin: 5px;
    }

    .menu {
        position: absolute;
        margin-left: 10px;
    }
}

.main-window {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
}

.board-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    width: 100%;
}

.board-inner {
    flex: 1;
    display: flex;
    // min-height: 0;
    // overflow-x: hidden;
}

.attack-zone {
    background-color: rgba(125, 13, 13, 0.41);
    display: flex;
    padding: 0px 10px;

    .attack-title {
        margin: auto;
        transform: rotate(-90deg);
        // transform-origin: 0 0;
        text-transform: uppercase;
        height: 30px;
    }

    .battle {
        // background-color: rgba(128, 128, 143, 0.371);
        height: 100%;
        margin: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .battle-arrow {
        font-weight: bold;
        font-size: 2em;
        text-align: center;
    }

    hr {
        color: white;
    }
}

.right-side {
    // position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.prompt-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding-top: 40px;

    &.manual {
        background-color: rgb(255, 0, 0, 0.2);
    }

    >.game-menu-header {
        position: absolute;
        top: 0px;
        font-size: 20px;
        padding: 5px;
        width: 100%;
        color: #CCC;
        background-color: black;
    }

    .game-menu-trigger {
        color: #FFF;
        cursor: pointer;
        width: 30px;
        height: 30px;
        vertical-align: bottom;
    }

    >.game-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 10;
        background-color: black;
        border: 1px solid grey;
        right: 1px;
        top: 40px;
        padding: 4px;

        .game-menu-icon {
            width: 35px;
            height: 35px;
        }

        .game-menu-item {
            padding: 5px;
            // width: 100%;
            color: #999;
            margin-top: 5px;
        }

        .game-menu-item:hover {
            padding: 5px;
            // width: 100%;
            color: #CCC;
        }
    }
}

.concede {
    color: rgb(209, 53, 53) !important;

    svg {
        color: rgb(209, 53, 53) !important;
    }

    &:hover {
        color: red;
    }
}

.timer-log-area {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-end;
}

.timer h1 {
    position: absolute;
    left: 8px;
    bottom: 0px;
}

.play-area {
    overflow-x: auto;
}

.play-area,
.player-board {
    .drop-target {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // margin: 10px;
    }

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: 0;
}

.player-board {
    // margin: 10px;

    .taunt {
        margin-top: 15px;
    }

    border-bottom: 2px dashed $primary;

    &.our-side {
        margin: 0;

        .taunt {
            margin-top: -15px;
        }

        border-bottom: none;
        position:absolute;
        bottom: 0px;
    }
}

.stats-top {
    .panel {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.player-home-row {
    display: flex;
    // overflow-x: auto;
    // overflow-y: hidden;
}

.inset-pane {
    display: flex;
    flex-direction: column;
    width: 210px;
    justify-content: space-between;
    margin-right: 10px;
    bottom: 0px;
}

.inset-pane>div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-log {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-left: auto;
    width: $keyteki-card-width + 10;
    overflow: unset;
}

.card-log-arrow {
    text-align: center;
    background-color: rgba($secondary, 0.4);
    cursor: pointer;
}

.info-panel {
    font-size: 1.1em;
    z-index: 999;

    width: 800px;
}

.bluffTimer {
    width: 100%;
    margin-bottom: 10px;
}

.alertTimer {
    width: 100%;
    margin-bottom: 10px;
}

.advice {
    font-style: italic;
    margin-bottom: 8px;
}