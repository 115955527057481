@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.player-stats {
    display: flex;
    background-color: rgba(0, 0, 0, 0.9);
    color: #EEE;

    &.panel {
        margin: 0;
        border-radius: 0;
        padding: 5px;
        position: initial;
    }

    display: flex;
    align-items: center;


    &.active-player {
        background-color: rgba(green, 0.8);

        .state svg {
            color: white;
        }

        .state .die {
            color: white;
        }
    }
}

.state {
    height: 27px;
    padding-left: 5px;
    padding-right: 5px;
    border-right: solid 2px;
    display: flex;
    align-items: center;

    &:last-of-type {
        border-right: none;
    }

    img {
        height: 100%;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &.first-player {
        margin: 0px 5px;
        padding: 5px;
        text-align: center;
        background: $primary;
        border-radius: 0.25rem;
        border: 1px solid rgba(128, 128, 128, 0.53);
    }

    .disconnected {
        padding: 0px 3px;
        color: red;
        border: 1pt dashed red;
        background-color: black;

        &.awol {
            background-color: #DDD;
        }
    }
}


@mixin action-colors($text, $background) {
    color: $text !important;
    background: $background;
    border-color: $text;

}

.action {
    @include action-colors(#333, #FFF);
    border-radius: 4px;
    // margin-left: 5px;
    margin-right: 5px;
    // height: 24px;
    // width: 26px;
    padding: 0px 5px;
    text-align: center;
    display: inline-block;
    z-index: $layer-cards + 1;
    font-size: 120%;
    border: 1pt solid black;

    &:last-child {
        margin-right: 0px;
    }

    svg {
        color: #333 !important;
    }
}

.action.exhausted {
    @include action-colors(#CCC, #889);
}

.stat-image {
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
    background-size: 28px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.stat-value {
    background: rgba(black, 0.6) center;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
}

.life-remaining {
    color: red;
    font-weight: bold;
    min-width: 30px;
}

.chimera-phase {
    background-color: rgb(107, 4, 4);
    color: white !important;
    // font-weight: bold;
    min-width: 30px;
}

.life-red {
    color: red;
}

.life-orange {
    color: orange;
}

.life-green {
    color: green;
}


.chat-status {
    position: absolute;
    right: 10px;
}

.btn-stat {
    padding: 0;
    font-size: 12px;
    height: 22px;
    width: 22px;
    background-color: transparent;
}

.btn-stat img {
    vertical-align: text-bottom;
}