@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    margin-top: 1pt;

    .messages {
        padding: 5px;
    }
}

.gamechat {
    overflow-y: hidden;
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-header {
    background-color: black; //$primary;
    text-align: end;
    padding-right: 5px;
    font-size: 20px;
    border-bottom: 2pt solid gray;
}

.chat-form {
    background-color: black; //$primary;
}

.typing-indicator {
    width: 40px;
    border-radius: 30px;
    position: absolute;
    bottom: 50px;
    left: 5px;
}