@import '../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';


.textlogo {
    // background-color: #7289DA;
    background-color: rgba(0, 0, 0, 0.5);
    height: 60px;
    border-radius: 5px;
}

.link-box-item {
    // margin: 10px;
    color: inherit;
}

.link-box-item p {
    margin-left: 6px;
}

.link-box-item ul {
    padding-left: 20px;
}

.two-column {
    columns: 2;
    width: 80%;
    margin: auto;
}

.lobby-content h2 {
    margin-bottom: 4px;
    color: white;
    font-size: 1.9em;
    font-weight: 400;
}

.lobby-content h3 {
    color: white;
    font-weight: 400;
}

.lobby-content p {
    margin-bottom: 0px;
}

.lobby-row {
    margin-bottom: 20px;

}

.decklist-img {
    height: 80px;
    margin: 5px;
}

.faq-entry {
    margin-left: 20px;
    margin-bottom: 20px;
}