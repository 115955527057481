.emoji-sprout:before {
    content: "\1F331";
}

.emoji-dizzy:before {
    content: "\1F4AB";
}

.no-avatar {
    margin-left: 5px;
}

.pending-player-name {
    min-width: 90px;
    margin: auto 2px;
}