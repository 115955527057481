@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.cardlog-wrapper {
    width: 210px;
    display: flex;
    flex-direction: column;
}

.cardlogex-wrapper {
    width: 216px;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    // overflow-x: hidden;
}

.last-card {
    border-radius: 6.25%;
    overflow: hidden;
    max-width: 130px;
    max-height: 182px;
    position: absolute;
    bottom: 0px;
}

.log-icon {
    border-radius: 3px;
    // margin: 5px;
    height: 45px;
    width: 45px;
    text-align: center;
    display: inline-block;
    z-index: $layer-cards + 1;
}

.action-play {
    color: rgb(255, 215, 0);

}

.action-use {
    color: green;
}

.action-attack {
    color: red;
}

.action-med {
    color: violet;
}

.action-pass {
    color: rgb(0, 148, 255);
}

.cardlog-die {
    width: 58px;
}

.log-card {
    display: flex;
    width: 100%;
    margin: 2px;
    padding: 1px 0px 1px 4px;

    &:hover {
        background-color: #333;
    }

    >.img-card {
        width: 45px;
        border-radius: 4px;
        // margin-left: 4px;
        // margin-right: 4px;
    }

    .log-info {
        margin-left: 4px;
    }

    .x-large .die {
        height: 45px;
        width: 45px;
        margin: 0px;
        line-height: unset;
    }

    .p1 {
        // font-weight: bold;
        color: #90ee90;
    }

    .p2 {
        // font-weight: bold;
        color: lightskyblue;
    }
}