@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';



.card-list-title {
    background-color: $primary;
    color: white;
    margin-bottom: 5px;
    text-align: center;
}

.card-list-cards {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .card-wrapper {
        margin: 0 5px 5px 0;
    }
}