@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.card {
    box-shadow: 1px 1px 1px $primary !important;

    .card-header {
        font-weight: normal;
        background-size: cover;
        color: $light;
    }

    &.border-primary {
        .card-header {
            background-image: url('~assets/img/panel-primary.png');
            border-color: $primary;
        }
    }

    &.border-danger {
        .card-header {
            background-image: url('~assets/img/panel-danger.png');
            border-color: $danger;
        }

        .menu-pane-source {
            background-color: $danger;
        }
    }

    &.border-purple {
        .card-header {
            background-image: url('~assets/img/panel-purple.png');
            border-color: purple !important;
        }

        .menu-pane-source {
            background-color: purple;
        }
    }

    &.border-border {
        box-shadow: 1px 1px 1px $border !important;

    }
}