@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.card-inspector {
    position: absolute !important;
    top: 40px;
    width: 210px;
    z-index: $layer-top;
    background-color: #000 !important;
}

.simple-card {
    position: relative;
    border-radius: 6.25%;
    overflow: hidden;
    // max-width: 130px;
    // max-height: 182px;

}

.inspector-control {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;

    .btn-sm {
        line-height: 1;
        font-size: 14px;
        min-width: 60px !important;
    }
}

.center-content {
    display: flex;
    justify-content: center;
}