@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.deck-status {
    text-align: center;
    padding: 4px 8px;
    border: 1px solid black;
    border-radius: 4px;
    color: black;
    display: inline-block;
    margin-left: 5px;

    &.valid {
        background: $success;
    }

    &.casual-play {
        background: $warning;
    }

    &.used {
        background: $warning;
    }

    &.popular {
        background: $warning;
    }

    &.notorious {
        background: $danger;
    }

    &.invalid {
        background: $danger;
    }
}

.popover-body {
    background-color: #0e0b10;
}

.popover {
    background-color: #0e0b10;
    color: white;
    //width: 300px;

    ul {
        padding: 0;
    }

    li {
        list-style: none;
        margin: 4px 0;
    }
}

.popover.bs-popover-right {
    .arrow {
        &:after {
            border-right-color: #0e0b10;
        }
    }
}