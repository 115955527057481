@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.message-list {
    height: 200px;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 4px;
    margin-bottom: 5px;
    overflow: auto;
    padding: 5px 10px;
}

.game-status {
    margin: 10px 0;
    padding: 3px 5px;
    background-color: rgba(169, 169, 169, 0.304);
    font-size: 18px;
}

.spectator-list {
    margin-bottom: 15px;
}

.unbold {
    font-weight: normal;
}

.newgame-header {
    display: flex;
    gap: 10px;
}

.pending-header-content {
    margin-left: 10px;
}

.start-game-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 8px;
}

.copy-game-link {
    position: absolute;
    right: 10px;
}