@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.deck-list {
    width: 100%;
    // overflow-y: auto;
    // height: 70vh;

    .table-hover {
        tbody {
            tr:hover {
                background-color: rgba($info, 0.3);
            }
        }
    }

    table {
        border: none;
        border-width: 0 1px 1px 0;
        border-spacing: 0;
        border-collapse: separate;

        th,
        td {
            border: none;
            border-top: 1pt solid grey;

            border-width: 1px 0 0 1px;
        }

        .deck-list-entry {
            td {
                padding: 6px;
            }
        }
    }

    .invalid {
        background-color: rgba($danger, 0.6);
    }

    .decklist-entry {
        padding: 5px;
        display: flex;
        min-width: 260px;
        background-color: unset;
        margin: unset;
        border-bottom: 1px solid grey !important;

        &.selected-deck {
            .card-header {
                background-color: rgba($primary, 0.7);
            }
        }

        .card-header {
            background-color: rgba(0, 0, 0, 0.6);
            padding: 5px;

            .toggle {
                width: 20px;
                height: 20px;
                margin-top: auto;
                // margin-bottom: auto;
                border: 1px solid grey;
                border-radius: 3px;
            }
        }

        .decklist-title {
            font-size: 15px;
            border: none;
            background-color: unset;
            color: white;
        }

    }

    .card {
        box-shadow: unset !important;

        .win-rate {
            width: 50px;
            margin-left: auto;
            text-align: center;
        }
    }

}

.pager {
    justify-content: right !important;
    padding: 10px 15px;
}

.decklist-accordion-header {
    display: flex;
    gap: 10px;
}

.invalidCount {
    color: red;
}

.deck-image {
    // padding-left: 0;
    // padding-right: 0;
    // width: 30px;
    border-radius: 2px;
}

.hover-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $layer-top;
}

.deck-expansion {
    max-width: 15px;
    margin: 2px;
}

.selected-deck {
    background-color: rgba($primary, 0.7);
}

.fave-hdr {
    text-align: center;
}

.decklist-title {
    font-size: 15px;
}

.win-rate {
    font-size: 15px;
}

.decklist-entry-image {
    width: 70px;
    min-width: 70px;

    height: 70px;
    border-radius: 4px;
    border: silver solid 2px;
}

.image-button,
.decklist-entry-image {
    &.the-dark-space-lord {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-dark-space-lord.jpg');
        background-size: cover;
    }

    &.the-ninja {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-ninja.jpg');
        background-size: cover;
    }

    &.the-xeno-hunter {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-xeno-hunter.jpg');
        background-size: cover;
    }

    &.the-wizard {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-wizard.jpg');
        background-size: cover;
    }

    &.the-cleric {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-cleric.jpg');
        background-size: cover;
    }

    &.the-light-space-monk {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-light-space-monk.jpg');
        background-size: cover;
    }

    &.the-kaiju {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-kaiju.jpg');
        background-size: cover;
    }

    &.the-dinosaurs {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-dinosaurs.jpg');
        background-size: cover;
    }

    &.the-ice-queen {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-ice-queen.jpg');
        background-size: cover;
    }

    &.the-demon {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-demon.jpg');
        background-size: cover;
    }

    &.the-ghost-pirate {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-ghost-pirate.jpg');
        background-size: cover;
    }

    &.the-haunted-armor {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-haunted-armor.jpg');
        background-size: cover;
    }

    &.the-berserker {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-berserker.jpg');
        background-size: cover;
    }

    &.the-killbot {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-killbot.jpg');
        background-size: cover;
    }

    &.the-medbot {
        background-image: url('https://cdn.ashteki.com/bacon/champion-buttons/the-medbot.jpg');
        background-size: cover;
    }

    &.dangerous-darkness {
        background-image: url('https://cdn.ashteki.com/bacon/banners/dangerous-darkness.jpg');
        background-size: cover;
    }

    &.light-fight {
        background-image: url('https://cdn.ashteki.com/bacon/banners/light-fight.jpg');
        background-size: cover;
    }

    &.powerful-potential {
        background-image: url('https://cdn.ashteki.com/bacon/banners/powerful-potential.jpg');
        background-size: cover;
    }

    &.spooky-specters {
        background-image: url('https://cdn.ashteki.com/bacon/banners/spooky-specters.jpg');
        background-size: cover;
    }

    &.lacking-limits {
        background-image: url('https://cdn.ashteki.com/bacon/banners/lacking-limits.jpg');
        background-size: cover;
    }
}

.banner-buttons {
    .image-button {
        width: 200px;
        height: 142px;
    }

    justify-content: center;
}

.premium-lozenge {
    padding: 2px 4px;
    border-radius: 4px;
    display: inline-block;
    background-color: rgb(251, 219, 89);
    font-size: 14px;
    color: black;

    &.login {
        cursor: pointer;
    }

    &.sm {
        font-size: 10px;
    }
}

.premium {
    cursor: not-allowed;

    .decklist-entry-image {
        filter: grayscale(100%);
    }
}

.patreon-row {
    margin-top: 4px;

    .unlock-advice {
        margin: 4px;
    }
}