@import 'colours';

$navbar-height: 45px;
$scrollbar-width: 20px;

// Z-index layers

$layer-bottom: 1;
$layer-cards: 10;
$layer-card-index: 11;
$layer-card-hover: 15;
$layer-pile-header: 20;
$layer-card-collection: 100;
$layer-prompt: 110;
$layer-card-menu: 120;
$layer-top: 9999;

// card

$keyteki-card-width: 65px;
$keyteki-card-height: 91px;

$keyteki-card-lg-width: $keyteki-card-width * 1.4;
$keyteki-card-lg-height: $keyteki-card-height * 1.4;

$keyteki-card-xl-width: $keyteki-card-width * 2;
$keyteki-card-xl-height: $keyteki-card-height * 2;

$keyteki-card-sm-width: $keyteki-card-width * 0.6;
$keyteki-card-sm-height: $keyteki-card-height * 0.6;

$controlledBox: 0 0 1px 2px $controlled-border-color;

$header-border: rgba(71, 58, 56, 0.95);

$upgrade-offset: 15px;
$upgrade-offset-sm: $upgrade-offset * 0.6;
$upgrade-offset-lg: $upgrade-offset * 1.4;
$upgrade-offset-xl: $upgrade-offset * 2;

$status-offset: 15px;
$status-offset-sm: $status-offset * 0.6;
$status-offset-lg: $status-offset * 1.4;
$status-offset-xl: $status-offset * 2;

// how far down should a card be offset
$underneath-offset: 0px;
// $underneath-offset: 15px;
$underneath-offset-sm: $underneath-offset * 0.6;
$underneath-offset-lg: $underneath-offset * 1.4;
$underneath-offset-xl: $underneath-offset * 2;