.user-alts {
    .img-card {
        width: 120px;
        border-radius: 6px;
    }
}

.admin-card {
    .img-card {
        width: 120px;
        border-radius: 6px;
    }

}