@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.deck-status-summary {
    // font-size: 12px;

    .valid {
        color: $success;
    }

    .popular {
        color: $warning;
    }

    .used {
        color: $warning;
    }

    .notorious {
        color: $danger;
    }

    .invalid {
        color: $danger;
    }
}