@use "sass:math";
@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.player-home-row-container {
    display: flex;
    width: 100%;
    margin-left: 4px;

    &.active-player {
        // background-color: $active-player-color;
    }

    .energy-wrapper {
        margin: 10px 0px;
        display: flex;
        flex-direction: column;
        min-height: 99px;

        &.bottom {
            flex-direction: column-reverse;
        }

    }
}


.champion-row {
    display: flex;
    width: 100%;
    margin-left: 4px;

    &.active-player {
        // background-color: $active-player-color;
    }

    >.card-wrapper {
        min-height: 99px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &.bottom {
            flex-direction: column-reverse;
        }

        // min-width: 150px;
    }

}

.stat-icon {
    width: 24px !important;
    height: 24px;
    display: inline-block;
    text-align: center;
}

.energy-box {
    min-width: 80px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
}