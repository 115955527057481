@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.card-wrapper {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    user-select: none;
    position: relative;
    // min-height: $keyteki-card-height;

    // &.small {
    //     min-height: $keyteki-card-sm-height ;
    // }

    // &.large {
    //     min-height: $keyteki-card-lg-height ;
    // }

    // &.x-large {
    //     min-height: $keyteki-card-xl-height ;
    // }

}

.card-frame {
    position: relative;
}

.card-image {
    left: 0;
    position: absolute;
    top: 0;
    pointer-events: none;

    &.square {
        height: unset !important;
    }

    &.small {
        &.vertical {
            height: $keyteki-card-sm-height;
            width: $keyteki-card-sm-width;
        }

        &.horizontal {
            height: $keyteki-card-sm-width;
            width: $keyteki-card-sm-height;
        }
    }

    &.large {
        &.vertical {
            height: $keyteki-card-lg-height;
            width: $keyteki-card-lg-width;
        }

        &.horizontal {
            height: $keyteki-card-lg-width;
            width: $keyteki-card-lg-height;
        }
    }

    &.x-large {
        &.vertical {
            height: $keyteki-card-xl-height;
            width: $keyteki-card-xl-width;
        }

        &.horizontal {
            height: $keyteki-card-xl-width;
            width: $keyteki-card-xl-height;
        }
    }

    &.vertical {
        height: $keyteki-card-height;
        width: $keyteki-card-width;
    }

    &.horizontal {
        height: $keyteki-card-width;
        width: $keyteki-card-height;
    }
}

.card-name {
    font-size: 10px;
    line-height: 14px;
    width: 100%;
}

@keyframes glowing {
    from {
        background-color: #749a02;
        box-shadow: 0 0 9px #333;
    }

    50% {
        background-color: #91bd09;
        box-shadow: 0 0 18px #91bd09;
    }

    to {
        background-color: #749a02;
        box-shadow: 0 0 9px #333;
    }
}

// @keyframes new-card {
//     0% {
//         box-shadow: 0 0 1px 2px  rgba($info, 0.7),
//         10px 10px 10px 5px   rgba($info, 0.7),
//         -10px -10px 10px 5px   rgba($info, 0.7),
//         0 0 15px 10px   rgba($info, 0.7)
//     }
//     100% {
//         box-shadow: 
//         0 0 0px 2px  rgba($info, 1),
//         0px 0px 0px 0px rgba($info, 1),
//         0px 0px 0px 0px   rgba($info, 1),
//         0 0 0px 0px   rgba($info, 1)
//     }
// }

.game-card {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.4), inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    overflow: hidden;
    z-index: $layer-cards;

    margin: auto;

    &:hover {
        z-index: $layer-card-hover;

        // >.card-menu-icon {
        //     z-index: 16;
        // }
    }

    &.small {
        &.vertical {
            height: $keyteki-card-sm-height;
            width: $keyteki-card-sm-width;


        }

        &.horizontal {
            height: $keyteki-card-sm-width;
            width: $keyteki-card-sm-height;
        }

        &.square {
            height: $keyteki-card-sm-width;
            width: $keyteki-card-sm-width;
        }
    }

    &.large {
        &.vertical {
            height: $keyteki-card-lg-height;
            width: $keyteki-card-lg-width;
        }

        &.horizontal {
            height: $keyteki-card-lg-width;
            width: $keyteki-card-lg-height;
        }

        &.square {
            height: $keyteki-card-lg-width;
            width: $keyteki-card-lg-width;
        }
    }

    &.x-large {
        &.vertical {
            height: $keyteki-card-xl-height;
            width: $keyteki-card-xl-width;
        }

        &.horizontal {
            height: $keyteki-card-xl-width;
            width: $keyteki-card-xl-height;
        }

        &.square {
            height: $keyteki-card-xl-width;
            width: $keyteki-card-xl-width;
        }
    }

    &.vertical {
        height: $keyteki-card-height;
        width: $keyteki-card-width;
    }

    &.horizontal {
        height: $keyteki-card-width;
        width: $keyteki-card-height;
    }

    &.square {
        height: $keyteki-card-width;
        width: $keyteki-card-width;
    }

    &.selectable {
        box-shadow: 0 0 10px 1px fade-out(lighten($primary, 60%), 0.1);

        &.controlled {
            box-shadow: $controlledBox,
                0 0 10px 1px fade-out(lighten($primary, 60%), 0.1);
        }
    }

    &.unselectable {
        filter: brightness(60%);
    }

    &.can-play {
        animation: glowing 2000ms infinite;

        &.controlled {
            box-shadow: $controlledBox,
                0 0 10px 1px fadeout(lighten($primary, 60%), 10%);
        }
    }

    &.in-danger {
        box-shadow: 0 0 1px 2px red;
    }

    // &.saved {
    //     box-shadow: 0 0 1px 2px green;
    // }

    &.selected {
        box-shadow: 0 0 1px 4px $secondary;

        &.controlled {
            box-shadow: $controlledBox, 0 0 1px 4px $secondary;
        }
    }

    &.new {
        box-shadow: 0 0 0px 2px rgb(255, 215, 0);
        animation: new-card 1s cubic-bezier(0.5, 1, 0.5, 1) forwards;
    }

    &.controlled {
        box-shadow: $controlledBox;
    }

    &.attacker {
        border: 2px solid red;
    }

    &.defender {
        border: 2px solid blue;
    }

    &.combat-top {
        margin-top: 15px;
    }

    &.combat-bottom {
        margin-bottom: 15px;
    }

    &.exhausted {
        filter: grayscale(50%) brightness(100%);
        transform: rotate(90deg);

        .counter {
            filter: unset;
        }
    }


}

.card-menu-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    border-radius: 4px;

    z-index: 12;
    padding: 0px 4px;
    background-color: #000;
    // z-index: $layer-card-menu - 1;

}

.drag-preview {
    pointer-events: none;
    position: fixed;

    opacity: 0.5;
    z-index: $layer-top;
}

.upgrade {
    margin-top: -$upgrade-offset - $keyteki-card-height;

    &.small {
        margin-top: -$upgrade-offset - $keyteki-card-sm-height;
    }

    &.large {
        margin-top: -$upgrade-offset-lg - $keyteki-card-lg-height;
    }

    &.x-large {
        margin-top: -$upgrade-offset-xl - $keyteki-card-xl-height;
    }
}

.status {
    position: relative;
    margin-left: -35px;

    width: 63px;
    height: 90px;

    // margin-top: -$status-offset - $keyteki-card-height;

    // &.small {
    //     margin-top: -$status-offset - $keyteki-card-sm-height;
    // }

    // &.large {
    //     margin-top: -$status-offset-lg - $keyteki-card-lg-height;
    // }

    // &.x-large {
    //     margin-top: -$status-offset-xl - $keyteki-card-xl-height;
    // }
}

.status-0 {
    margin-left: -50px;
}

@for $i from 1 through 10 {
    .upgrade-#{$i} {
        z-index: $layer-cards - $i;

    }
}

@for $i from 0 through 6 {
    .status-#{$i} {
        z-index: $layer-cards - $i - 1;
    }
}

.card-ordering {
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 26px;
    left: 50%;
    line-height: 24px;
    margin: 0 0 0 -12px;
    position: absolute;
    text-align: center;
    top: -28px;
    width: 24px;
    z-index: $layer-card-menu;
}

.underneath {
    margin-left: 15px;
    position: relative;
    z-index: $layer-cards - 1;

    margin-top: $underneath-offset - $keyteki-card-height;

    &.small {
        margin-top: $underneath-offset-sm - $keyteki-card-sm-height;
    }

    &.large {
        margin-top: $underneath-offset-lg - $keyteki-card-lg-height;
        margin-left: 20px;
    }

    &.x-large {
        margin-top: $underneath-offset-xl - $keyteki-card-xl-height;
        margin-left: 25px;
    }
}

.under {
    position: absolute
}

.flags {
    position: absolute;
    top: 0px;
    left: 2px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 4px;
    width: 100%;
}

.flag {
    z-index: 10;
    padding: 0;
    text-align: center;
    line-height: 18px;
    font-size: 11px;
    height: 18px;
    width: 18px;
}

.attack {
    background-color: #9C4545;
    // position: absolute;
    left: 2px;
}

.defense {
    background-color: #4D66AA;
    // position: absolute;
    left: 2px;
}

.life {
    background-color: rgba(0, 141, 0, 0.9);
}

.recover {
    background-color: rgba(0, 0, 201, 0.9);
    position: absolute;
    right: 2px;
}

.Spell .recover {
    background-color: rgb(103, 102, 104);
    position: relative;
    left: 2px;
}

.spellfocus {
    background-color: rgba(201, 0, 167, 0.9);
    position: absolute;
    left: 2px;
}

.darkbg {
    // background: rgba(0,0,0,0.6);
    text-shadow: 1px 1px 2px #000;
    border-radius: 4px;
}

.card-chain-bad {
    color: red;
    position: absolute;
    top: 0px;
    right: 5px;
    z-index: $layer-cards + 10;
}

.card-chain-ok {
    color: green;
    position: absolute;
    top: 0px;
    right: 5px;
    z-index: $layer-cards + 10;
}


.card-alt-icon {
    position: absolute;
    bottom: 5px;
    z-index: 500;
    margin-left: 5px;
}

.card-alt-icon button {
    background-color: lightsteelblue;
    border-radius: 4px;
}

.card-index {
    background-color: #777; // $primary;
    border: 1.3pt solid #FFF;
    border-radius: 10px;
    color: #FFF;
    font-size: 0.8em;
    margin-left: 2px;
    min-width: 20px;
    position: absolute;
    text-align: center;
    top: 2px;
    z-index: $layer-card-index;
}

.effects {
    position: absolute;
    top: 1px;
    display: flex;
    width: 100%;
}

.effect {
    height: 18px;
    width: 18px;
    margin-left: 1px;
    z-index: 999;
    margin: auto;
}

.effect-acquired img {
    width: 100%;
}

.inner-wrapper {
    display: flex;
    justify-content: center;
}