@import '../../styles/variables';
@import '../../styles/colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';


.games-popup {
    position: absolute;
    // left: -80px;
    // top: 0px;
    min-width: 200px;
    // list-style-type: none;
    z-index: $layer-top;

}

.spectators-popup {
    z-index: $layer-top;
}

.absolute-panel {
    background-color: rgba(0, 0, 0, 0.85);
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid rgba(71, 58, 56, 0.95);
    overflow: visible;
}

.game-count-menu {
    position: relative;

    & ul {
        list-style: inside;
    }
}

.empty-game {
    color: darkorange;
    font-weight: bold;
}

.game-count-list-item {
    list-style-type: circle;

}