.game-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px
}

.image-button {
    width: 150px;
    min-width: 150px; // don't resize smaller
    height: 200px;
    border: 2px solid rgb(210, 210, 194);
    border-radius: 8px;
    position: relative;
    padding: 0px;
    background-color: unset;
    // filter: grayscale(.5);
    // -webkit-filter: grayscale(.5);

    &.pvp {
        background-image: url('https://cdn.ashteki.com/bacon/emerge-from-shadows.png');
        background-position: 50% 22%;
        background-size: 170%;
    }

    .img-button-label {
        width: 100%;
        height: 35px;
        line-height: 35px;
        background-color: rgb(0, 0, 0, 0.9);
        text-align: center;
        color: #EEE;
        font-size: 18px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        bottom: 0px;
        position: absolute;
    }

    .img-button-header {
        width: 100%;
        height: 25px;
        line-height: 25px;
        background-color: rgb(251, 219, 89);
        text-align: center;
        // color: #EEE;
        font-size: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        filter: unset;
        -webkit-filter: unset;

        top: 0px;
        position: absolute;

        &.discord {
            background-color: rgba(150, 150, 150, 0.5);
            color: white;
        }
    }

    &:hover {
        // filter: grayscale(0);
        // -webkit-filter: grayscale(0);
        border: 3px solid rgb(250, 229, 6);

        .img-button-label {
            color: #FFF;
        }
    }
}

@media screen and (max-width: 1280px) {

    /* For general iPad layouts */
    .image-button {
        width: 130px;
        min-width: 130px; // don't resize smaller
        height: 150px;
    }
}


.decks-link {
    // width: 300px;
    // min-width: 250px;
    // height: 150px;
    background-image: url('https://cdn.ashteki.com/bacon/divine-cleansing.png');
    background-position: 55% 25%;
    background-size: 160%;

}

.records-link {
    // width: 300px;
    // min-width: 250px;
    // height: 150px;

    background-image: url('https://cdn.ashteki.com/300/law-of-assurance.jpg');
    background-position: 45% 55%;
    background-size: cover;

}