// This file contains styles that need assigning to a component but that component either doesn't exist yet or needs refactoring
@import './variables';
@import './colours';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.panel {
    background-color: rgba(0, 0, 0, 0.8);
}

.panel {
    box-shadow: 1px 1px 2px $border !important;

    .panel-heading {
        font-weight: normal;
        background-size: cover;
    }
}

.panel-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $layer-pile-header;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 14px;
    background: rgba(0, 0, 0, 0.6);
    text-shadow: 1px 1px 2px #000;
    border-radius: 4px;
}

.panel-header-rotated {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: $layer-pile-header;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 14px;
    // margin-right: 18px;
    // background: rgba(0, 0, 0, 0.6);
    // text-shadow: 1px 1px 2px #000;
    // border-radius: 4px;

    margin-bottom: 0;

    transform: rotate(90deg);
    transform-origin: 0 0;

}

.drop-target {
    display: inline-block;
    position: relative;
}

.shadow {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.4), inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}