.game-formats {
    margin-top: -15px;

}

.game-format {
    border-radius: 8px;
    color: white;
    margin-right: 8px;

    &.draft {
        background-color: rgb(0, 153, 255);
    }

    &.precon {
        background-color: rgb(169, 45, 45);
    }

    &.podcon {
        background-color: rgb(2, 96, 2);
    }

    &.form-check {
        padding-left: 1.8rem;
        padding-top: 4px;
        padding-right: 8px;
        padding-bottom: 4px;
    }
}

.format-info-link {
    color: #4593b8;
    text-decoration: underline;
}

.img-game-format {
    width: 150px;

}

.format-item {
    width: 33%;
    display: inline-block;
}