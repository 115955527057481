@import '../../styles/variables';

.champ-set {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;

    .champ-group {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .img-card {
        // height: $keyteki-card-lg-height;
        width: $keyteki-card-lg-width;
    }

    .game-card {
        &.champ {
            // cursor: pointer;
        }

        margin: 0px;
    }

    .draftpick-wrapper {
        text-align: center;
    }

    .snake-controls {
        width: 100px;
        font-weight: bold;
        align-content: center;

        .complete {

            color: #90ee90;
            font-weight: bold;
            font-size: 24px;
        }
    }
}