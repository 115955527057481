@import 'colours';
@import 'variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import 'bootstrap';
@import 'bacon';

@import './fixmeunassigned.scss';


html,
body {
    height: 100%;
}

.body,
.container,
#component {
    height: 100%;
}

.container {
    padding-left: 0px;
    padding-right: 0px;
}

body {
    font-size: 0.9rem;
}

.wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    overflow-y: auto;
}

a {
    color: skyblue;

    &:hover {
        color: $info;
        text-decoration: none;
    }

    text-decoration: none;
}

.btn {
    font-size: 0.9rem;

    &:not(.dropdown-toggle):not(.mini) {
        margin-right: 10px;
        min-width: 100px;

    }

    &.mini {
        min-width: 50px;

        &.active {
            // background-color: rgb(0, 162, 255) !important;
        }
    }

}

.btn-extra {
    color: white;
}

.btn:not(.def) {
    font-size: 0.8rem;

    .spinner-border {
        vertical-align: middle;
        margin-right: 5px;
    }

    &:not(.dropdown-toggle):not(label) {
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 130px;
        // font-family: Keyforge, Helvetica, sans-serif;

        &:hover {
            text-shadow: 0px 0px 6px $white;
            transition: all 0.4s ease 0s;

            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
            // outline: 1px solid red;
            box-shadow: 0 0 0 0.1rem rgba(200, 200, 200, 0.75);
        }

        &:active {
            background-color: transparent;
        }

        &:focus:active {
            background-color: transparent;
        }
    }


    &.btn-primary:not(label) {
        background-image: url('~assets/img/button-primary.png');

        &:hover {
            background-image: url('~assets/img/button-primary-hover.png');
        }

        &:focus {
            background-image: url('~assets/img/button-primary-hover.png');
        }

        &.active {
            border: 1pt solid yellow;
        }
    }

    &.btn-default {
        &:not(.dropdown-toggle) {

            background-image: url('~assets/img/button-orange.png');

            &:hover {
                background-image: url('~assets/img/button-orange-hover.png');
            }

            &:focus {
                background-image: url('~assets/img/button-orange-hover.png');
            }
        }
    }

    &.btn-secondary {
        &:not(.dropdown-toggle) {
            background-image: url('~assets/img/button-orange.png');

            &:hover {
                background-image: url('~assets/img/button-orange-hover.png');
            }

            &:focus {
                background-image: url('~assets/img/button-orange-hover.png');
            }
        }
    }

    &.btn-danger {
        &:not(.dropdown-toggle) {
            background-image: url('~assets/img/button-red.png');

            &:hover {
                background-image: url('~assets/img/button-red-hover.png');
            }

            &:focus {
                background-image: url('~assets/img/button-red-hover.png');
            }
        }
    }

    &.btn-success {
        background-image: url('~assets/img/button-green.png');

        &:hover {
            background-image: url('~assets/img/button-green-hover.png');
        }

        &:focus:active {
            background-image: url('~assets/img/button-green-hover.png');
        }
    }

    &.btn-grey {
        background-image: url('~assets/img/button-grey.png');

        &:hover {
            background-image: url('~assets/img/button-grey-hover.png');
        }

        &:focus:active {
            background-image: url('~assets/img/button-grey-hover.png');
        }
    }


    &.btn-stretch {
        background-size: 100% 32px;
    }
}

.alert {
    ul {
        list-style: none;
        margin-bottom: 0;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $primary;
}

.table-totals tbody tr:last-child {
    font-weight: bold;
}

.bg {
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body {
    font-family: "SaveYourBaconWebfont";

}

.content {
    padding-top: $navbar-height + 5px;
}

.card {
    margin-bottom: 10px;
}

.btn-icon {
    padding: 0.375rem 0.75rem;
}

h4 {
    font-size: 0.9rem;
}

h3 {
    font-size: 1.1rem;
}

.react-bs-table-sizePerPage-dropdown {
    .btn-default {
        background-image: none;
    }
}

.rrt-message {
    background: $dark !important;
}

.rrt-confirm {
    background: $primary !important;
}

.rrt-button {
    color: white;
}

.rrt-button:hover {
    color: $dark !important;
}

.clickable {
    cursor: pointer;
    cursor: hand;
}

pre {
    background-color: rgba(0, 0, 0, 0.65);
    color: $primary;
    border: none;
}


/* Fix input boxes */

// .form-control {
//     width: 100%;
//     min-height: 34px;
//     padding: 6px 12px;
//     margin: 5px 0;
//     // background-color: #fff;
//     background-image: none;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//     -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
//     -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;


// }

// .form-horizontal {
//     .form-group {
//         margin-left: 0;
//         margin-right: 0;
//     }
// }

.inline {

    display: inline-block;

}

.def {
    margin-top: 5px;
}